<template>
  <div id="addCard">
    <div class="top-tips">请绑定实名认证本人/法人的银行卡</div>
    <div class="info-item">
      <div class="label">持卡人</div>
      <input v-model="name" type="text" placeholder="姓名" />
    </div>
    <div class="info-item">
      <div class="label">卡号</div>
      <input v-model="cardNum" type="text" placeholder="请输入银行卡号" />
    </div>
    <div class="top-tips">请选择银行卡类型</div>
    <div class="info-item picker" @click="showBank = true">
      <div class="item-left">
        <div class="label">银行</div>
        <p :class="bankType == '请选择银行' ? '' : 'active'">{{bankType}}</p>
      </div>
      <img src="@images/right-arrow.png" alt />
    </div>

    <div class="info-item picker" @click="showCard = true">
      <div class="item-left">
        <div class="label">卡类型</div>
        <p :class="cardType == '请选择银行卡类型' ? '' : 'active'">{{cardType}}</p>
      </div>
      <img src="@images/right-arrow.png" alt />
    </div>
    <div class="top-tips">请填写个人信息</div>

    <div class="info-item">
      <div class="label">手机号</div>
      <input
        v-model="phone"
        oninput="if(value.length>11)value=value.slice(0,11)"
        type="text"
        placeholder="请输入银行预留手机号"
      />
    </div>

    <div :class="isAll ? 'submit-btn' : 'submit-btn active'" @click="submit">下一步</div>

    <van-popup v-model="showBank" position="bottom">
      <van-picker
        show-toolbar
        :columns="bankColumns"
        @confirm="onBankConfirm"
        @cancel="showBank = false"
      />
    </van-popup>

    <van-popup v-model="showCard" position="bottom">
      <van-picker
        show-toolbar
        :columns="cardColumns"
        @confirm="onCardConfirm"
        @cancel="showCard = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { Dialog } from "vant";

import { allBank, addBank } from "../api/requist";
export default {
  components: {},
  props: [],
  data() {
    return {
      isAllRight: false,
      flag: true,
      showCard: false,
      showBank: false,
      cardColumns: ["信用卡", "储蓄卡"],
      bankColumns: [],
      bankType: "请选择银行",
      cardType: "请选择银行卡类型",
      name: "",
      cardNum: "",
      phone: "",
      bankList: []
    };
  },
  created() {},
  mounted() {
    this.getBankList();
  },
  watch: {},
  computed: {
    isAll() {
      if (
        this.bankType != "请选择银行" &&
        this.cardType != "请选择银行卡类型" &&
        this.cardNum != "" &&
        this.name != "" &&
        this.phone != ""
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    onBankConfirm(values) {
      this.bankType = values;
      this.showBank = false;
    },
    onCardConfirm(values) {
      this.cardType = values;
      this.showCard = false;
    },
    async getBankList() {
      let formData = new FormData();
      let res = await allBank(formData);
      if (res.code === 200) {
        res.data.forEach(item => {
          this.bankColumns.push(item.bank_name);
        });
        this.bankList = res.data;
      }
    },

    async submit() {
      if (!this.flag) {
        return;
      }
      this.flag = false;
      if (this.name === "") {
        return Toast("请输入姓名");
      }

      if (this.cardNum === "") {
        return Toast("请输入卡号");
      }

      if (this.bankType === "请选择银行") {
        return Toast("请选择银行");
      }

      if (this.cardType === "请选择银行卡类型") {
        return Toast("请选择银行卡类型");
      }

      if (this.phone === "") {
        return Toast("请输入银行预留手机号");
      }

      if (this.phone === "") {
        return Toast("请输入银行预留手机号");
      }

      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        return Toast("请输入正确的手机号");
      }
      let bank_sn = "";

      this.bankList.forEach(item => {
        if (item.bank_name === this.bankType) {
          bank_sn = item.bank_sn;
        }
      });

      let that = this;
       let formData = new FormData();
       formData.append("merch_id", localStorage.getItem('merch_id'));
       formData.append('cardholder',  this.name);
       formData.append('card_numbe',this.cardNum);
       formData.append('card_type', this.cardType);
       formData.append('telephone', this.phone);
       formData.append('bank_sn', bank_sn);
      let res = await addBank(formData);
      if (res.code === 200) {
        Toast({
          message: "添加成功",
          onClose: function() {
            that.$router.push({ path: "/myCard" });
          }
        });
      } else {
        Dialog({
          message:
            "为了您的资金安全，只能绑定店长的银行卡，请确保持卡人与店长一致",
          confirmButtonText: "知道了",
          confirmButtonColor: "#FF6737"
        });
      }
    }
  }
};
</script>
<style lang="scss" >
 @import '../common/css/theme.scss';
#addCard {
  min-height: 100vh;
  background: rgba(245, 247, 250, 1);
  .top-tips {
    padding: 3.2* 3.75px 4.8* 3.75px 0;
    box-sizing: border-box;
    font-size: 3.2* 3.75px;
    color: rgba(144, 147, 153, 1);
    height: 9.33* 3.75px;
    text-align: left;
  }

  .info-item {
    display: flex;
    align-items: center;
    padding: 0 4.8* 3.75px;
    box-sizing: border-box;
    height: 11.73* 3.75px;
    background: rgba(255, 255, 255, 1);
    margin-bottom: 0.27* 3.75px;
    &.picker {
      justify-content: space-between;
    }

    img {
      width: 1.6* 3.75px;
      height: 3.2* 3.75px;
    }
    .item-left {
      display: flex;
      align-items: center;
      p {
        font-size: 3.73* 3.75px;
        color: #c0c4cc;
        &.active {
          color: #303133;
        }
      }
    }
    .label {
      width: 18.67* 3.75px;
      font-size: 3.73* 3.75px;
      text-align: left;
      line-height: 5.33* 3.75px;
      color: rgba(144, 147, 153, 1);
    }

    input {
      outline: none;
      border: none;
      color: #303313;
      font-size: 3.73* 3.75px;
      &::placeholder {
        color: rgba(192, 196, 204, 1);
      }
    }
  }

  .submit-btn {
    width: 90.4* 3.75px;
    height: 12.27* 3.75px;
    background: rgba(192, 196, 204, 1);
    border-radius: 1* 3.75px;
    margin: 6.4* 3.75px auto 0;
    font-size: 4.27* 3.75px;
    line-height: 12.27* 3.75px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    &.active {
      background: $theme-color;
      color: rgba(255, 255, 255, 1);
    }
  }
}
</style>
